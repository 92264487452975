import 'babel-polyfill'
import 'lazysizes'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import 'js/polyfills/clipPath'
import 'events-polyfill'

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
import 'js/utils/equalHeight'
import 'js/utils/datatables'
import 'js/utils/ie11'
import 'js/utils/safari'
import 'js/utils/browsehappy'

import objectFitImages from 'object-fit-images'

objectFitImages()

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

//===============================================================/
//  =components
//===============================================================/

if (document.querySelector('.typ .btn')) {
  import(/* webpackChunkName: "compLink" */ 'js/components/link')
}
if (document.querySelector('.-js-share')) {
  import(/* webpackChunkName: "compShare" */ 'js/components/share')
}
if (document.querySelector('.-js-share-price')) {
  import(/* webpackChunkName: "compStockFeed" */ 'js/components/stockFeed')
}
if (document.querySelector('.-js-sub-nav-links')) {
  import(/* webpackChunkName: "compSubNavLinks" */ 'js/components/subNavLinks')
}
if (document.querySelector('#backToTop')) {
  import(/* webpackChunkName: "compBackToTop" */ 'js/components/backToTop')
}
if (document.querySelector('table')) {
  import(/* webpackChunkName: "customTable" */ 'js/components/customTable')
}
if (document.querySelector('.js-form-filter')) {
  import(/* webpackChunkName: "filters" */ 'js/components/filters')
}

//===============================================================/
//  =blocks
//===============================================================/

// if (document.querySelector('.-js-news')) {
// This has CORS errors for now....
// import(/* webpackChunkName: "blockNews" */ 'js/blocks/news')
// }
if (document.querySelector('.js-hero')) {
  import(/* webpackChunkName: "blockHero" */ 'js/blocks/hero')
}
if (document.querySelector('.-js-banner')) {
  import(/* webpackChunkName: "blockBanner" */ 'js/blocks/banner')
}
if (document.querySelector('.-js-banner-inline')) {
  import(/* webpackChunkName: "blockBannerInline" */ 'js/blocks/bannerInline')
}
if (document.querySelector('.-js-banner-panel')) {
  import(/* webpackChunkName: "blockBannerPanel" */ 'js/blocks/bannerPanel')
}
if (document.querySelector('.-js-panel-expander')) {
  import(/* webpackChunkName: "blockPanelExpander" */ 'js/blocks/panelExpander')
}
if (document.querySelector('.-js-business-diagram')) {
  import(/* webpackChunkName: "blockBusinessDiagram" */ 'js/blocks/businessDiagram')
}
if (document.querySelector('.-js-business-careers-diagram')) {
  import(/* webpackChunkName: "blockBusinessCareersDiagram" */ 'js/blocks/businessCareersDiagram')
}
if (document.querySelector('.-js-business-model')) {
  import(/* webpackChunkName: "blockBusinessModel" */ 'js/blocks/businessModel')
}
if (document.querySelector('.-js-content')) {
  import(/* webpackChunkName: "blockContent" */ 'js/blocks/content')
}
if (document.querySelector('.-js-colcon')) {
  import(/* webpackChunkName: "blockColumnContent" */ 'js/blocks/columnContent')
}
if (document.querySelector('.-js-figure-wrap')) {
  import(/* webpackChunkName: "blockFigure" */ 'js/blocks/figure')
}
if (document.querySelector('.-js-titlecontent')) {
  import(/* webpackChunkName: "blockTitleContent" */ 'js/blocks/titleContent')
}
if (document.querySelector('.-js-quote')) {
  import(/* webpackChunkName: "blockQuote" */ 'js/blocks/quote')
}
if (document.querySelector('.-js-related-wrap')) {
  import(/* webpackChunkName: "blockRelated" */ 'js/blocks/related')
}
if (document.querySelector('.-js-teammember')) {
  import(/* webpackChunkName: "blockTeam" */ 'js/blocks/team')
}
if (document.querySelector('.-js-video')) {
  import(/* webpackChunkName: "blockVideo" */ 'js/blocks/video')
}
if (document.querySelector('.-js-image-carousel')) {
  import(/* webpackChunkName: "blockImageCarousel" */ 'js/blocks/imageCarousel')
}
if (document.querySelector('.-js-latest-news-block')) {
  import(/* webpackChunkName: "blockLatestNews" */ 'js/blocks/latestNews')
}
if (document.querySelector('.-js-tabs')) {
  import(/* webpackChunkName: "blockTabContent" */ 'js/blocks/tabContent')
}
if (document.querySelector('.-js-map-block')) {
  import(/* webpackChunkName: "blockMap" */ 'js/blocks/map')
}
if (document.querySelector('.-js-leadership-block')) {
  import(/* webpackChunkName: "blockLeadership" */ 'js/blocks/leadership')
}
if (document.querySelector('.-js-featured-case-study-block')) {
  import(/* webpackChunkName: "blockFeaturedCaseStudies" */ 'js/blocks/featuredCaseStudies')
}
if (document.querySelector('.-js-featured-tile-block')) {
  import(/* webpackChunkName: "blockFeaturedTiles" */ 'js/blocks/featuredTiles')
}
if (document.querySelector('.-js-twitter')) {
  import(/* webpackChunkName: "blockTwitter" */ 'js/blocks/twitter')
}
if (document.querySelector('.js-team-accordion-block')) {
  import(/* webpackChunkName: "teamAccordion" */ 'js/blocks/teamAccordion')
}
if (document.querySelector('.js-sub-nav-links')) {
  import(/* webpackChunkName: "subNavLinks" */ 'js/blocks/subNavLinks')
}
if (document.querySelector('.js-inline-video')) {
  import(/* webpackChunkName: inlineVideo" */ 'js/blocks/inlineVideo')
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.header')) {
  import(/* webpackChunkName: "layoutHeader" */ 'js/layouts/header')
}
if (document.querySelector('.-js-nav-modal')) {
  import(/* webpackChunkName: "layoutNav" */ 'js/layouts/nav')
}
if (document.querySelector('.-js-nav-diagram-modal')) {
  import(/* webpackChunkName: "layoutNavDiagram" */ 'js/layouts/navDiagram')
}
if (document.querySelector('.-js-search-modal')) {
  import(/* webpackChunkName: "layoutSearch" */ 'js/layouts/search')
}

//===============================================================/
//  =pages
//===============================================================/

if (document.querySelector('.ss-contactpage')) {
  import(/* webpackChunkName: "contactPage" */ 'js/pages/contactPage')
}

if (document.querySelector('.js-google-map')) {
  import(/* webpackChunkName: "googleMaps" */ 'js/utils/googleMap')
}

if (document.querySelector('.-js-animate-resource')) {
  import(/* webpackChunkName: "resource" */ 'js/pages/resource')
}

if (document.querySelector('.js-resource-list')) {
  import(/* webpackChunkName: "resourceList" */ 'js/pages/resourceList')
}

if (document.querySelector('.js-team-list')) {
  import(/* webpackChunkName: "teamList" */ 'js/pages/teamList')
}

//===============================================================/
//  =iframe manager
//===============================================================/

if (document.querySelector('iframe.responsive')) {
  import(/* webpackChunkName: "frameManager" */ 'js/utils/frameManager')
}
